import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const captchaEnabled = true;

const Recaptcha = ({ clickCaptcha }) => {
  if ( captchaEnabled ) {
    return (
      <ReCAPTCHA
        sitekey="6LevqRETAAAAAPL1fLoNhUTH3rSA3LNEMHwqAVv6"
        onChange={ (val) => clickCaptcha(val) }
        // ref={ this.captcha }
      />
    );
  }
  return (
    <button
      onClick={ () => clickCaptcha("tokentest") }
    >
      click
    </button>
  );
}
export default Recaptcha;
